import React, { useEffect, useMemo, useRef, useState } from "react";
import "./GiftcardAnonymous.scss";
import { useDispatch, useSelector } from "react-redux";
import { replace } from "connected-react-router";
import {
  Alert,
  Col,
  Form,
  Input,
  Radio,
  Row,
  Skeleton,
  Space,
  Spin,
  Typography,
} from "antd";
import { getPublicPaymentIntent } from "services/paymentMethod.service";
import {
  CardElement,
  Elements,
  PaymentElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import {
  GIFTCARD_CONFIG_KEY,
  PROMO_GIFTCARD_CONFIG_KEY,
} from "utils/constants";
import {
  FormButton,
  FormCheckbox,
  FormDatePicker,
  FormInput,
  FormInputArea,
} from "components/FormItems/FlatFormItems";

import moment from "moment";

import {
  CheckCircleOutlined,
  LoadingOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { validateEmail } from "utils/common";
import {
  getAnonymousPurchasablePromoGCs,
  purchaseAnonymousGiftCard,
  purchaseGiftCard,
  validateGuestAccount,
} from "services/booking.service";
import Modal from "components/Modal/Modal";
import UsernamePopup from "containers/Dashboard/UsernamePopup";
import { Content } from "antd/lib/layout/layout";
import Accordion, { Panel } from "components/Accordion/Accordion";
import InfoText from "components/InfoText/InfoText";
import { setLoading } from "redux/actions/loading.actions";
import { openNotificationWithIcon } from "utils/Notification";
import HorizontalSlide from "components/HorizontalSlide/HorizontalSlide";
import PromoGiftCard from "./components/PromoGiftcard";
import PromoGiftCardModal from "./components/PromoGiftCardModal";
import Spinner from "components/Spinner/Spinner";

const { Title, Text } = Typography;
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PK);

const CARD_ELEMENT_OPTIONS = {
  style: {
    base: {
      color: "#32325d",
      fontFamily: "Roboto, sans-serif",
      fontSmoothing: "antialiased",
      fontSize: "14px",
      "::placeholder": {
        fontSize: 14,
        color: "#A8ACB3",
      },
    },
    invalid: {
      color: "#fa755a",
      iconColor: "#fa755a",
    },
  },
};

export function Giftcard() {
  const { user } = useSelector((state) => ({
    user: state.user,
  }));
  const dispatch = useDispatch();
  const stripe = useStripe();
  const elements = useElements();

  const [form1] = Form.useForm();
  const [form2] = Form.useForm();
  const value = Form.useWatch("amount", form1);
  const isRecipient = Form.useWatch("isRecipient", form1);
  const [showSuccess, setShowSuccess] = useState(false);
  const firstName = Form.useWatch("recipientFirstName", form1);
  const lastName = Form.useWatch("recipientLastName", form1);
  const email = Form.useWatch("recipientEmail", form1);
  const isValidated = Form.useWatch("isValidated", form1);
  const username = Form.useWatch("username", form1);
  const hasMultipleGuests = Form.useWatch("hasMultipleGuests", form1);
  const count = Form.useWatch("count", form1);
  const amount = Form.useWatch("amount", form1);
  const customValue = Form.useWatch("customValue", form1);
  const [panel, setPanel] = useState("1"); // "1"
  const [completed, setCompleted] = useState([""]); // no value
  const promotionalProductId = Form.useWatch("promotionalProductId", form1);

  const [paymentIntent, setPaymentIntent] = useState();
  const [showTCs, setShowTCs] = useState(false);
  const [formInfo, setFormInfo] = useState({
    data: null,
    isLoading: false,
  });
  const [promoGCs, setPromoGCs] = useState({
    data: null,
    isLoading: true,
  });
  const abortConRef = useRef();

  const { miscSettings, miscLoading } = useSelector((state) => ({
    miscSettings: state.misc.miscSettings,
    miscLoading: state.misc.isLoading,
  }));

  const design = useMemo(() => {
    try {
      const giftCardConfig = miscSettings?.find(
        (setting) => setting.key === GIFTCARD_CONFIG_KEY
      );
      if (giftCardConfig)
        return JSON.parse(decodeURI(giftCardConfig?.value || "{}"));
    } catch (e) {
      console.log(e);
      return;
    }
  }, [miscSettings, miscLoading]);

  const promoTCs = useMemo(() => {
    try {
      const giftCardConfig = miscSettings?.find(
        (setting) => setting.key === PROMO_GIFTCARD_CONFIG_KEY
      );
      if (giftCardConfig)
        return JSON.parse(decodeURI(giftCardConfig?.value || ""))?.notes || "";
    } catch (e) {
      console.log(e);
      return;
    }
  }, [miscSettings, miscLoading]);

  const [showUsernamePopup, setShowUsernamePopup] = useState(false);
  const [isValidating, setIsValidating] = useState(false);
  const [lastCheckedInfo, setLastCheckedInfo] = useState({
    email: "",
    firstName: "",
    lastName: "",
  });

  const checkInformation = () => {
    if (!validateEmail(email?.trim?.() || "")) return;

    setIsValidating(true);
    if (abortConRef.current) abortConRef.current.abort();
    abortConRef.current = new AbortController();
    dispatch(
      validateGuestAccount({
        firstName,
        lastName,
        emailAddress: email,
      })
    )
      .then((res) => {
        if (res?.status !== "success") {
          return;
        }

        if (res?.count > 1) {
          setShowUsernamePopup(true);
        } else if (res?.count === 1) {
          form1.setFields([
            {
              name: "recipientEmail",
              errors: [],
            },
            {
              name: "count",
              value: 1,
            },
            {
              name: "isValidated",
              value: true,
            },
            {
              name: "username",
              value: undefined,
            },
            {
              name: "hasMultipleGuests",
              value: "no",
            },
          ]);
          setLastCheckedInfo({
            email,
            firstName,
            lastName,
          });
        } else {
          form1.setFields([
            {
              name: "recipientEmail",
              errors: [],
            },
            {
              name: "count",
              value: 0,
            },
            {
              name: "isValidated",
              value: true,
            },
            {
              name: "username",
              value: undefined,
            },
            {
              name: "hasMultipleGuests",
              value: "no",
            },
          ]);
          setLastCheckedInfo({
            email,
            firstName,
            lastName,
          });
        }
      })
      .finally(() => {
        setIsValidating(false);
      });
  };

  useEffect(() => {
    if (showSuccess === false && panel == "2") {
      const sellingPrice = amount === "custom" ? customValue : amount;
      if (sellingPrice || promotionalProductId)
        dispatch(
          getPublicPaymentIntent({
            amount: sellingPrice,
            productId: promotionalProductId,
          })
        ).then((res) => {
          setPaymentIntent(res?.data);
        });
    }
  }, [showSuccess, panel]);

  useEffect(() => {
    dispatch(getAnonymousPurchasablePromoGCs())
      .then((res) => {
        if (res.status === "success") {
          setPromoGCs({
            data: res.data,
            isLoading: false,
          });
        } else {
          setPromoGCs({
            data: null,
            isLoading: false,
          });
        }
      })
      .catch(() => {
        setPromoGCs({
          data: null,
          isLoading: false,
        });
      });
  }, []);

  const renderVerificationStatus = () => {
    const hasAllFields =
      email === lastCheckedInfo?.email &&
      firstName === lastCheckedInfo.firstName &&
      lastName === lastCheckedInfo.lastName;

    if (isValidating)
      return (
        <Spin
          indicator={
            <LoadingOutlined
              style={{ fontSize: 20, lineHeight: 0, color: "#486baf" }}
              spin
            />
          }
        />
      );
    if (count === 0 && hasAllFields)
      return (
        <p
          className="verifiedText firstLetterUppercase"
          style={{
            lineHeight: 1,
            margin: 0,
          }}
        >
          NEW GUEST
        </p>
      );

    return (
      <>
        {hasAllFields ? (
          <div
            style={{
              display: "flex",
              gap: 8,
            }}
          >
            <UserOutlined onClick={() => setShowUsernamePopup(true)} />
            <p
              className="verifiedText firstLetterUppercase"
              style={{
                lineHeight: 1,
                margin: 0,
              }}
            >
              VERIFIED
            </p>
          </div>
        ) : (
          <p
            className="verifyBtn firstLetterUppercase"
            onClick={checkInformation}
            disabled={isRecipient}
            style={{
              lineHeight: 1,
              margin: 0,
            }}
          >
            VERIFY
          </p>
        )}
      </>
    );
  };

  const renderForm = () => {
    const {
      sellingPrices = [50, 100, 150],
      customValueEnabled = true,
      notes,
      minimumValue = 200,
    } = design ?? {};
    return (
      <>
        {formInfo?.data?.hasInvalidEmail && (
          <Alert
            message="Invalid Receiver Email Provided."
            type="error"
            className="giftcardFeedbackAlert"
            closable
          />
        )}
        {promoGCs.isLoading ? (
          <Spinner />
        ) : (
          promoGCs.data?.length > 0 && (
            <HorizontalSlide
              className="promoGiftCardSliders"
              title="Promo Gift Cards"
            >
              {promoGCs.data.map((item, index) => {
                return (
                  <PromoGiftCard
                    name={item.programName}
                    description={item.notes}
                    price={item.sellingPrice}
                    isSelected={item.productId === promotionalProductId}
                    onClick={() => {
                      form1.setFieldsValue({
                        amount: undefined,
                        promotionalProductId: item.productId,
                      });
                    }}
                  />
                );
              })}
            </HorizontalSlide>
          )
        )}
        <FormInput hidden name="promotionalProductId" />
        <Title level={4} className="tradeGothic">
          Gift Card Amount
        </Title>
        <Form.Item
          name="amount"
          style={{ marginBottom: 20 }}
          rules={[
            {
              required: !promotionalProductId,
              message: "amount required",
            },
          ]}
        >
          <Radio.Group
            className="giftCardValues"
            onChange={(e, value) => {
              form1.setFieldsValue({
                promotionalProductId: undefined,
              });
            }}
          >
            {sellingPrices?.map((price) => {
              return <Radio value={price}>${price}</Radio>;
            })}
            {customValueEnabled && (
              <Radio value={"custom"}>
                {value === "custom" ? (
                  <Form.Item
                    className="no-error-field"
                    style={{ margin: 0 }}
                    rules={[
                      {
                        required: true,
                      },
                      {
                        min: 1,
                      },
                    ]}
                    name="customValue"
                    required
                  >
                    <Input
                      min={1}
                      prefix="$"
                      type="number"

                      // onChange={(e) => setCustomValue(Number(e.target.value))}
                      // value={customValue}
                    />
                  </Form.Item>
                ) : (
                  <> Custom</>
                )}
              </Radio>
            )}
          </Radio.Group>
        </Form.Item>
        <Title level={4} className="tradeGothic" style={{ marginBottom: 6 }}>
          From
        </Title>
        <Row gutter={16}>
          <Col xs={12}>
            <FormInput
              label="First Name"
              variant="underlined"
              name="senderFirstName"
              placeholder={"First name"}
              formItemStyles={{ marginBottom: 12 }}
              required
            />
          </Col>
          <Col xs={12}>
            <FormInput
              label="Last Name"
              variant="underlined"
              name="senderLastName"
              placeholder={"Last name"}
              formItemStyles={{ marginBottom: 12 }}
              required
            />
          </Col>
          <Col xs={24}>
            <FormInput
              validateFirst={true}
              label="Sender Email Address"
              variant="underlined"
              name="senderEmail"
              placeholder={"name@domain.com"}
              formItemStyles={{ marginBottom: 14 }}
              required
            />
          </Col>
        </Row>
        <Title level={4} className="tradeGothic" style={{ marginBottom: 6 }}>
          To
        </Title>
        <Row gutter={16}>
          <Col xs={12}>
            <FormInput
              label="First Name"
              variant="underlined"
              name="recipientFirstName"
              placeholder={"First name"}
              formItemStyles={{ marginBottom: 12 }}
              required={!isRecipient}
              disabled={isRecipient}
              onChange={(e) => {
                if (e.target.value !== lastCheckedInfo.firstName)
                  form1.setFields([
                    {
                      name: "isValidated",
                      value: false,
                    },
                  ]);
              }}
            />
          </Col>
          <Col xs={12}>
            <FormInput
              label="Last Name"
              variant="underlined"
              name="recipientLastName"
              placeholder={"Last name"}
              formItemStyles={{ marginBottom: 12 }}
              required={!isRecipient}
              disabled={isRecipient}
              onChange={(e) => {
                if (e.target.value !== lastCheckedInfo.firstName)
                  form1.setFields([
                    {
                      name: "isValidated",
                      value: false,
                    },
                  ]);
              }}
            />
          </Col>
          <Col xs={24}>
            <FormInput
              validateFirst={true}
              label="Reciepient Email Address"
              variant="underlined"
              name="recipientEmail"
              placeholder={"name@domain.com"}
              formItemStyles={{ marginBottom: 14 }}
              disabled={isRecipient}
              addonAfter={<>{renderVerificationStatus()}</>}
              rules={[
                {
                  required: !isRecipient,
                  message: "The name is required.",
                },
                {
                  type: "email",
                  message: "The input is not valid E-mail!",
                },
                {
                  message: "User not validated",
                  validator: (_, value) => {
                    if (isRecipient) return Promise.resolve();
                    if (
                      email === lastCheckedInfo?.email &&
                      firstName === lastCheckedInfo.firstName &&
                      lastName === lastCheckedInfo.lastName
                    )
                      return Promise.resolve();
                    else return Promise.reject("Username not validated");
                  },
                },
              ]}
              onChange={(e) => {
                if (e.target.value !== lastCheckedInfo.email)
                  form1.setFields([
                    {
                      name: "isValidated",
                      value: false,
                    },
                  ]);
              }}
              onBlur={() => {
                if (
                  email &&
                  firstName &&
                  lastName &&
                  !(
                    email === lastCheckedInfo?.email &&
                    firstName === lastCheckedInfo.firstName &&
                    lastName === lastCheckedInfo.lastName
                  )
                ) {
                  checkInformation();
                }
                // dispatch();
              }}
            />
          </Col>
        </Row>
        <FormInput hidden={true} rules={[]} name={"count"} />
        <FormInput hidden={true} rules={[]} name={"username"} />
        <FormInput hidden={true} rules={[]} name={"hasMultipleGuests"} />
        <FormInput hidden={true} rules={[]} name={"isValidated"} />

        <FormInputArea
          label="Personal Notes"
          name="personalNotes"
          variant="underlined"
        />
        {/* <Title level={4} className="tradeGothic">
          From
        </Title> */}
        <FormDatePicker
          form={form1}
          placeholder={"Send Date"}
          label={"Send Date"}
          name="scheduledSendDate"
          variant="underlined"
          formItemStyles={{ marginBottom: 14 }}
          required
          validateFirst
          // defaultValue={moment("01-01-1994", "DD-MM-YYYY")}
          rules={[
            {
              required: true,
              message: "*Required",
            },
            // {
            //   message:
            //     "Your age should be between 18 and 85. Kindly contact our support.",
            //   validator: (_, value) => {
            //     if (
            //       value.isSameOrBefore(moment().subtract(18, "years")) &&
            //       value.isAfter(moment().subtract(85, "years"))
            //     )
            //       return Promise.resolve();
            //     else return Promise.reject();
            //   },
            // },
          ]}
          disabledDate={(current) =>
            current.isSameOrBefore(
              moment().set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
            )
          }
        />
        <Typography.Link
          underline
          disabled={!design?.notes}
          onClick={() => setShowTCs(true)}
        >
          {promotionalProductId && "Promo"} Gift Card Terms & Conditions
        </Typography.Link>
        <FormButton
          id="submit"
          htmlType="submit"
          type="submit"
          text={"Save & Continue"}
          rounded={true}
          formItemStyles={{ marginBottom: 12, marginTop: 12 }}
        />
      </>
    );
  };
  const renderLoader = () => {
    return (
      <div>
        <Skeleton
          active
          paragraph={{
            rows: 6,
          }}
        />
      </div>
    );
  };
  function renderTCs() {
    if (miscLoading) {
      return <Spinner />;
    } else if (promotionalProductId) {
      return promoTCs;
    } else {
      return design?.notes;
    }
  }

  const renderPrice = () => {
    // amount === "custom" ? customValue : amount
    if (amount === "custom") {
      return customValue;
    } else if (promotionalProductId !== undefined) {
      const promoGC = promoGCs.data.find(
        (item) => item.productId === promotionalProductId
      );
      return promoGC?.sellingPrice;
    } else {
      return amount;
    }
  };

  if (showSuccess)
    return (
      <div className="gc">
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
          <CheckCircleOutlined
            style={{
              fontSize: 100,
              color: "#52c41a",
            }}
          />
          <Title
            style={{
              fontSize: 24,
              fontFamily: "tradeGothic",
              marginTop: 12,
            }}
          >
            Your gift card has been purchased successfully. Thank you.
          </Title>
          <span>
            If you want to purchase another one,{" "}
            <Typography.Link
              onClick={() => {
                form1.resetFields();
                setShowSuccess(false);
                setPanel("1");
              }}
            >
              click here{" "}
            </Typography.Link>
          </span>
        </div>
      </div>
    );

  return (
    <Content className="signupContainer">
      <Space direction="vertical" className="signupWrapper">
        <Typography.Title level={3}>Welcome!</Typography.Title>
        <Accordion
          panel={panel}
          onChange={(key) => {
            setPanel(key);
          }}
          completed={completed}
        >
          <Panel
            key={1}
            title="Gift card"
            isCompleted={completed.includes("1")}
            completedMeta={
              <Space direction="vertical" size={0} className="signupUserInfo">
                <Text>
                  Receiver: {firstName} {lastName} ({email})
                </Text>
                <Text>Amount: ${renderPrice()} </Text>
                {/* <Text>
                  {city?.locationName ?? "NA"}, {state?.locationName ?? "NA"}{" "}
                  {zipCode ?? "NA"}
                </Text> */}
              </Space>
            }
          >
            <Form
              className="gcForm"
              id="payment-form"
              form={form1}
              requiredMark={false}
              layout="vertical"
              onFinishFailed={({ values, errorFields, outOfDate }) => {
                form1.scrollToField(errorFields[0].name, {
                  scrollMode: "if-needed",
                  block: "center",
                  behavior: "smooth",
                });
              }}
              onFinish={(formValues) => {
                const completedSet = new Set([...completed]);
                completedSet.add("1");
                setCompleted([...completedSet]);
                setPanel("2");
                // const { scheduledSendDate, amount, customValue, ...values } =
                //   formValues;
                // let data = {
                //   ...values,
                // };
                // data.scheduledSendDate = moment(scheduledSendDate).format();
                // if (amount === "custom") {
                //   data.giftCardPrice = formValues.customValue;
                // } else {
                //   data.giftCardPrice = formValues.amount;
                // }

                // if (isRecipient) {
                //   delete data.username;
                //   data.firstName = user.firstName;
                //   data.lastName = user.lastName;
                //   data.recipientEmail = user.email;
                // }

                // dispatch(purchaseGiftCard(data)).then((res) => {
                //   if (res?.status === "success") {
                //     // onSave();
                //   }
                // });
              }}
            >
              {miscLoading ? renderLoader() : renderForm()}
            </Form>
          </Panel>
          <Panel
            key={2}
            title={
              <span
                className="flex alignCenter"
                style={{ fontFamily: "inherit", fontSize: "inherit" }}
              >
                <span
                  style={{
                    marginRight: 16,
                    fontFamily: "inherit",
                    fontSize: "inherit",
                  }}
                >
                  Payment
                </span>
              </span>
            }
            isCompleted={completed.includes("2")}
            completedMeta={<>ASdasd sdasdsd </>}
            isClickable={true}
          >
            <Form
              layout="vertical"
              form={form2}
              onFinish={async (values) => {
                form1
                  .validateFields()
                  .then(() => {
                    dispatch(setLoading(true));
                    stripe
                      .confirmCardPayment(paymentIntent, {
                        payment_method: {
                          card: elements.getElement(CardElement),
                          billing_details: {
                            name: "Jenny Rosen",
                          },
                        },
                      })
                      .then(function (result) {
                        if (!!result?.paymentIntent?.id) {
                          const submittableData = form1.getFieldsValue();
                          submittableData.recipientUserName =
                            submittableData.username;

                          submittableData.isAlreadyPaid = true;
                          submittableData.scheduledSendDate =
                            submittableData?.scheduledSendDate?.format();
                          submittableData.giftCardPrice =
                            amount === "custom" ? customValue : amount;
                          submittableData.paymentGatewayTransactionId =
                            result?.paymentIntent?.id;
                          submittableData.productId = promotionalProductId;
                          dispatch(purchaseAnonymousGiftCard(submittableData))
                            .then((res) => {
                              if (res?.status === "success") {
                                setShowSuccess(true);
                              }
                            })
                            .catch(() => {})
                            .finally(() => {
                              dispatch(setLoading(false));
                            });
                        } else {
                          dispatch(setLoading(false));
                          openNotificationWithIcon(
                            "error",
                            "Error",
                            "Please provide valid card information"
                          );
                        }
                        // Handle result.error or result.paymentIntent
                      })
                      .catch((error) => {
                        dispatch(setLoading(false));
                        openNotificationWithIcon(
                          "error",
                          "Error",
                          error?.message || "Something went wrong"
                        );
                      });
                  })
                  .catch(() => {
                    setPanel("1");
                  });
              }}
            >
              <div style={{ maxWidth: 360 }}>
                <CardElement
                  options={{ ...CARD_ELEMENT_OPTIONS, showIcon: true }}
                />
              </div>
              <FormButton
                id="submit"
                htmlType="submit"
                type="submit"
                text={"Make Payment"}
                rounded={true}
                formItemStyles={{ marginBottom: 12, marginTop: 12 }}
              />
            </Form>
          </Panel>
        </Accordion>
      </Space>

      <UsernamePopup
        name="username"
        username={username}
        visible={showUsernamePopup}
        onCancel={() => setShowUsernamePopup(false)}
        continueWithoutUsername={() => {
          form1.setFields([
            {
              name: "recipientEmail",
              errors: [],
            },
            {
              name: "isValidated",
              value: true,
            },
            {
              name: "username",
              value: "",
            },
            {
              name: "backendStatus",
              value: "",
            },
            {
              name: "hasMultipleGuests",
              value: "no",
            },
          ]);
          setLastCheckedInfo({
            email,
            firstName,
            lastName,
          });
          setShowUsernamePopup(false);
        }}
        handleSave={(username) => {
          form1.setFields([
            {
              name: "recipientEmail",
              errors: [],
            },
            {
              name: "count",
              value: 2,
            },
            {
              name: "isValidated",
              value: true,
            },
            {
              name: "username",
              value: username,
            },
            {
              name: "hasMultipleGuests",
              value: "yes",
            },
          ]);
          setLastCheckedInfo({
            email,
            firstName,
            lastName,
          });
          setShowUsernamePopup(false);
        }}
      />
      <Modal
        title="Gift Card Terms & Conditions"
        visible={showTCs}
        onCancel={() => setShowTCs(false)}
        footer={null}
      >
        <Typography.Text level={4} className="tradeGothic whiteSpacePreWrap">
          {renderTCs()}
        </Typography.Text>
      </Modal>
      <PromoGiftCardModal />
    </Content>
  );
}

const StripeAddCardModalWrapper = (props) => {
  return (
    <Elements stripe={stripePromise}>
      <Giftcard {...props} />
    </Elements>
  );
};

export default StripeAddCardModalWrapper;
