import { PaymentMethodType } from "utils/enums";
import { actions } from "../actions/paymentMethod.action";

const INITIAL_STATE = {
  paymentMethods: [],
  cash: [],
  wallets: [],
  credit: {
    balance: 0,
    isLoading: true,
  },
  customerWallets: {
    data: [],
    isLoading: true,
  },
};

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case actions.SET_PAYMENT_METHODS:
      const cards = action.data?.filter?.(
        (record) => record.type === PaymentMethodType.card
      );
      const cash = action.data?.filter?.(
        (record) => record.type === PaymentMethodType.cash
      );
      const wallets = action.data?.filter?.(
        (record) => record.type === PaymentMethodType.wallet
      );
      return { ...state, paymentMethods: cards, cash, wallets };
    case actions.ADD_PAYMENT_METHOD:
      return {
        ...state,
        paymentMethods: [...state.paymentMethods, action.data],
      };
    case actions.UPDATE_PAYMENT_METHOD:
      const updatedPaymentMethods = state.paymentMethods.map(
        (paymentMethod) => {
          if (paymentMethod.paymentMethodId === action.data?.paymentMethodId) {
            return { ...paymentMethod, ...action.data };
          }
          return paymentMethod;
        }
      );
      return { ...state, paymentMethods: updatedPaymentMethods };
    case actions.DELETE_PAYMENT_METHOD:
      const filteredPaymentMethods = state.paymentMethods.filter(
        (paymentMethod) =>
          paymentMethod.paymentMethodId !== action?.paymentMethodId
      );
      return { ...state, paymentMethods: filteredPaymentMethods };
    case actions.SET_PRIMARY_METHOD:
      const paymentMethods = state.paymentMethods.map((paymentMethod) => {
        if (paymentMethod.paymentMethodId === action.paymentMethodId)
          return {
            ...paymentMethod,
            isPrimary: true,
            // metaData: { isPrimary: "true" }, // stripe meta-data is in string form
          };
        else if (paymentMethod.paymentMethodId === action.prevPaymentMethodId)
          return {
            ...paymentMethod,
            isPrimary: false,
            // metaData: { isPrimary: "false" },
          };
        else return paymentMethod;
      });
      return { ...state, paymentMethods };
    case actions.SET_CREDIT:
      return { ...state, credit: { ...state.credit, ...action.data } };
    case actions.SET_CUSTOMER_WALLETS:
      return {
        ...state,
        customerWallets: {
          ...state.customerWallets,
          ...action.data,
        },
      };
    case actions.RESET_PAYMENT_METHODS:
      return INITIAL_STATE;
    default:
      return state;
  }
};
export default reducer;
