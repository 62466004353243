import React from "react";
import PropTypes from "prop-types";

import { Badge, Typography } from "antd";

import { RewardType } from "utils/enums";

import "../GiftcardAnonymous.scss";

const { Title, Text } = Typography;

function PromoGiftCard({
  name,
  price,
  discount,
  description,
  isSelected,
  onClick = () => {},
}) {
  const renderContent = () => {
    return (
      <div
        className={`promoGiftCard ${isSelected ? "selected" : ""}`}
        onClick={onClick}
      >
        <div className="content">
          <Title level={4} className="tradeGothic" style={{ color: "#4A6AAF" }}>
            {name}
          </Title>
          <div className="innerContent">
            <>
              {description &&
                description.split("\n").map((ele) => {
                  return <Text>{ele}</Text>;
                })}
            </>
          </div>
        </div>
        <div>
          <Title level={5} className="tradeGothic" style={{ marginBottom: 0 }}>
            ${price.toFixed(2)}
          </Title>
        </div>
      </div>
    );
  };

  if (isSelected)
    return (
      <Badge.Ribbon text="Selected" className="membershipRibbon" color="green">
        {renderContent()}
      </Badge.Ribbon>
    );
  // else if (data?.isPurchased && data?.isCancelled === true) {
  //   return (
  //     <Badge.Ribbon
  //       text="Cancelled"
  //       className="membershipRibbon"
  //       color="orange"
  //     >
  //       {renderContent()}
  //     </Badge.Ribbon>
  //   );
  // }
  return renderContent();
}

PromoGiftCard.propTypes = {};

export default PromoGiftCard;
