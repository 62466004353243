import {
  updateUser as updateUserAction,
  setUser,
  setRegisteringUser,
  loginUser as setLoginUser,
  logoutUser as clearUser,
  guestSession,
  setUpdatingUser,
  setProfiles,
  setCommunicationPreference,
  createProfile as createProfileAction,
  deleteProfile as deleteProfileAction,
  updateProfile as updateProfileAction,
  setShowProfileScreen,
} from "../redux/actions/user.actions";
import { openNotificationWithIcon } from "../utils/Notification";
import { getItem, setItem, clearStorage } from "../utils/storage";
import { request } from "./verb.services";
import { push } from "connected-react-router";
import { setLoading, setSubmitting } from "redux/actions/loading.actions";
import { batchActions } from "redux-batched-actions";
import queryString from "query-string";

import { resetIntakeForms } from "redux/actions/intakeForm.action";
import {
  resetPaymentMethods,
  setCustomerWallets,
} from "redux/actions/paymentMethod.action";
import { resetBooking } from "redux/actions/booking.action";
import { clearTimer } from "services/timer.service";

import { getAllPaymentMethods } from "services/paymentMethod.service";
import { getAllAddresses } from "services/addresses.service";
import { capitalize } from "lodash";
import { getRewards } from "./rewards.service";
import { setCredit } from "redux/actions/paymentMethod.action";

export const createUser = (data) => {
  return (dispatch) => {
    dispatch(setRegisteringUser(true));
    return request("Account/Register", "post", data, false)
      .then((response) => {
        const { password, ...userData } = data;
        setItem("user", userData);
        setItem("tokenContainer", { token: response.data.token });
        dispatch(setRegisteringUser(false));
        dispatch(updateUserAction(userData));
        // TODO: PUT GET PROFILES CALL IN THE RIGHT SPOT
        dispatch(getProfiles());
        return data;
      })
      .catch((e) => {
        dispatch(setRegisteringUser(false));
        openNotificationWithIcon(
          "error",
          "Error!",
          e?.response?.data?.message ||
            e?.response?.data?.Message ||
            "Network error has occured"
        );
      })
      .finally(() => {
        dispatch(setRegisteringUser(false));
      });
  };
};

export const socialLogin = (provider, authToken, data, redirectUrl) => {
  return (dispatch) => {
    dispatch(setLoading(true));
    return request(
      `Account/ExternalLogin?provider=${provider}&authToken=${authToken}`,
      "post",
      {},
      false
    )
      .then((response) => {
        const { token, customer: user } = response.data;
        if (user) {
          setItem("user", user);
          setItem("tokenContainer", { token });
          Promise.all([
            dispatch(getProfiles()),
            dispatch(getAllPaymentMethods(false)),
            dispatch(getAllAddresses(false)),
            dispatch(getRewards()),
            // dispatch(getCustomerWallet()),
            dispatch(getCustomerWallets()),
          ]).then((results) => {
            dispatch(setUser(user));
            // TODO: PUT GET PROFILES CALL IN THE RIGHT SPOT
            // if (results[0]?.length > 1) dispatch(setShowProfileScreen(true));
            dispatch(push(redirectUrl ?? "/"));
          });
        } else {
          // dispatch(updateUserAction(data));
          const { email, firstName, lastName } = data;
          const emailCapitalized = capitalize(email || "");
          const firstNameCapitalized = capitalize(firstName || "");
          const lastNameCapitalized = capitalize(lastName || "");
          dispatch(
            push(
              `/signup?email=${emailCapitalized}&firstName=${firstNameCapitalized}&lastName=${lastNameCapitalized}&external=true&token=${encodeURIComponent(
                token
              )}`
            )
          );
        }
      })
      .catch((error) => {
        let errorMessage = null;
        if (typeof error?.response?.data === "string") {
          errorMessage = error.response.data;
        }
        if (error?.response?.status === 404) {
        } else {
          openNotificationWithIcon(
            "error",
            "Error!",
            errorMessage ||
              error?.response?.data?.message ||
              "Network error has occured"
          );
        }
      })
      .finally(() => {
        dispatch(setLoading(false));
      });
  };
};

export const loginUser = (data, redirectUrl) => {
  return (dispatch) => {
    dispatch(setLoginUser(true));
    return request("Account/Login", "post", data, false)
      .then((response) => {
        const { token, customer: user } = response.data;
        setItem("user", user);
        setItem("tokenContainer", { token });
        // return new Promise(() => {
        Promise.all([
          dispatch(getProfiles()),
          dispatch(getAllPaymentMethods(false)),
          dispatch(getAllAddresses(false)),
          dispatch(getRewards()),
          // dispatch(getCustomerWallet()),
          dispatch(getCustomerWallets()),
        ]).then(async (results) => {
          await dispatch(setUser(user));
          // TODO: PUT GET PROFILES CALL IN THE RIGHT SPOT
          // if (results[0]?.length > 1) dispatch(setShowProfileScreen(true));
          dispatch(push(redirectUrl ?? "/"));
        });
        // });
      })
      .catch((e) => {
        let errorMesssage = e?.response?.data?.message ?? e.message;
        console.log("e", e, e?.response);
        if (e.response?.status === 401) {
          errorMesssage = "Incorrect username or password";
        }
        if (typeof e?.response?.data === "string") {
          errorMesssage = e.response.data;
        }
        openNotificationWithIcon(
          "error",
          "Error!",
          errorMesssage || "Network error has occured"
        );
      })
      .finally(() => {
        dispatch(setLoginUser(false));
      });
  };
};

export const verifyUser = () => {
  return (dispatch) => {
    dispatch(setLoading(true));
    return request("Account/Verify", "get", null, true)
      .then((response) => {
        console.log("response", response);
        // TODO: PUT GET PROFILES CALL IN THE RIGHT SPOT
        // return new Promise(() => {
        Promise.all([
          dispatch(getProfiles()),
          dispatch(getAllPaymentMethods(false)),
          dispatch(getAllAddresses(false)),
          dispatch(getRewards()),
          // dispatch(getCustomerWallet()),
          dispatch(getCustomerWallets()),
        ]).then((results) => {
          const user = getItem("user");
          setItem("user", response.data);
          dispatch(
            setUser({
              ...user,
              ...response.data,
            })
          );
        });
        // });
      })
      .catch((e) => {
        if (e?.message === "Network Error") {
          openNotificationWithIcon(
            "error",
            "Error!",
            "Network error has occured"
          );
          return;
        } else if (e?.response?.status === 401) {
          // logout user
          dispatch(logoutUser());
          return;
        }

        openNotificationWithIcon(
          "error",
          "Error!",
          e?.response?.data?.message ||
            e?.response?.data?.Message ||
            "Network error has occured"
        );
      })
      .finally(() => {
        dispatch(setLoading(false));
      });
  };
};

export const changePassword = (data) => {
  return (dispatch) => {
    dispatch(setSubmitting(true));
    return request("Account/Password", "patch", data, true)
      .then((response) => {
        openNotificationWithIcon(
          "success",
          "Password Updated!",
          "Password has been changed successfully"
        );
        return { status: "success" };
      })
      .catch((e, f) => {
        openNotificationWithIcon(
          "error",
          "Error!",
          e?.response?.message ||
            e?.response?.data?.[0]?.description ||
            "Your Password is incorrect"
        );
      })
      .finally(() => dispatch(setSubmitting(false)));
  };
};

export const logoutUser = () => {
  return (dispatch) => {
    return new Promise(async (_) => {
      clearStorage();
      dispatch(setLoading(true));
      await batchActions([
        dispatch(clearUser()),
        dispatch(resetIntakeForms()),
        dispatch(resetPaymentMethods()),
        dispatch(resetBooking()),
        dispatch(clearTimer()),
      ]);
      dispatch(setLoading(false));
      // dispatch(push("/"));
    });
  };
};

export const guestSessionStart = () => {
  return (dispatch) => {
    return new Promise((_) => {
      const user = getItem("user");
      // check and get user from localstorage
      dispatch(guestSession(user));
    });
  };
};

export const getUser = () => {
  return (dispatch) => {
    return request("Customer", "get", null, true)
      .then((response) => {
        dispatch(setUser(response.data));
        setItem("user", response.data);
        return { status: "Success", data: response.data };
      })
      .catch((e) => {
        if (e.message === "Network Error") {
          return { status: "Network Error" };
        } else if (e?.response?.status === 401) {
          return;
        }

        openNotificationWithIcon(
          "error",
          "Error!",
          e?.response?.data?.message ||
            e?.response?.data?.Message ||
            "Network error has occured"
        );
      });
  };
};

export const updateUser = (previous, data) => {
  return (dispatch) => {
    dispatch(setUpdatingUser(true));
    return request("Customer", "put", data, true)
      .then((response) => {
        data.phoneNumber = data.mobileNo;
        const userData = { ...previous, ...data };

        setItem("user", userData);
        dispatch(updateUserAction({ ...previous?.currentUser, ...data }));
        dispatch(setUpdatingUser(false));
        openNotificationWithIcon(
          "success",
          "Information Updated!",
          "Your information has been updated successfully"
        );
        return { data, status: "success" };
      })
      .catch((e) => {
        dispatch(setUpdatingUser(false));
        openNotificationWithIcon(
          "error",
          "Error!",
          e?.response?.data?.message ||
            e?.response?.data?.Message ||
            "Network error has occured"
        );
      });
  };
};

export const getProfiles = () => {
  return (dispatch) => {
    return request("Customers/Profiles", "get", null, true)
      .then((response) => {
        dispatch(setProfiles(response.data));
        return response.data;
      })
      .catch((e) => {
        openNotificationWithIcon(
          "error",
          "Error!",
          e?.response?.data?.message ||
            e?.response?.data?.Message ||
            "Network error has occured"
        );
      });
  };
};

export const updateCommunicationPreference = (profileId, data) => {
  return (dispatch) => {
    dispatch(setLoading(true));
    return request(
      `Customers/Profiles/${profileId}/CommunicationPreferences`,
      "patch",
      data,
      true
    )
      .then((response) => {
        dispatch(setCommunicationPreference(profileId, data));
        openNotificationWithIcon(
          "success",
          "Success!",
          "Your communication preferences have been updated successfully"
        );
      })
      .catch((e) => {
        openNotificationWithIcon(
          "error",
          "Error!",
          e?.response?.data?.message ||
            e?.response?.data?.Message ||
            "Network error has occured"
        );
      })
      .finally(() => {
        dispatch(setLoading(false));
      });
  };
};

export const setNewsletter = (interested) => {
  return (dispatch) => {
    dispatch(setLoading(true));
    return request(
      `Customer/NewsLetters?interested=${interested}`,
      "patch",
      null,
      true
    )
      .then((response) => {
        return { status: "success" };
      })
      .catch((e) => {
        openNotificationWithIcon(
          "error",
          "Error!",
          e?.response?.data?.message ||
            e?.response?.data?.Message ||
            "Network error has occured"
        );
      })
      .finally(() => {
        dispatch(setLoading(false));
      });
  };
};

export const createProfile = (data) => {
  return (dispatch) => {
    dispatch(setSubmitting(true));
    return request("Customers/Profiles", "post", data, true)
      .then(async (response) => {
        dispatch(createProfileAction(response.data));
        openNotificationWithIcon(
          "success",
          "Success!",
          "Family member added successfully"
        );
        dispatch(inviteFamilyMember(data));
        return { status: "success" };
      })
      .catch((e) => {
        openNotificationWithIcon(
          "error",
          "Error!",
          e?.response?.data?.message ||
            e?.response?.data?.Message ||
            "Network error has occured"
        );
      })
      .finally(() => {
        dispatch(setSubmitting(false));
      });
  };
};

export const inviteFamilyMember = (data) => {
  return (dispatch) => {
    return request("Customer/Invite", "put", data, true)
      .then((response) => {
        return { status: "success" };
      })
      .catch((e) => {
        openNotificationWithIcon(
          "error",
          "Error!",
          e?.response?.data?.message ||
            e?.response?.data?.Message ||
            "Network error has occured"
        );
      })
      .finally(() => {});
  };
};

export const updateProfile = (profileId, data) => {
  return (dispatch) => {
    dispatch(setSubmitting(true));
    return request(`Customers/Profiles/${profileId}`, "put", data, true)
      .then((response) => {
        dispatch(updateProfileAction(profileId, data));
        openNotificationWithIcon(
          "success",
          "Success!",
          "Family member information updated successfully"
        );
        return { status: "success" };
      })
      .catch((e) => {
        openNotificationWithIcon(
          "error",
          "Error!",
          e?.response?.data?.message ||
            e?.response?.data?.Message ||
            "Network error has occured"
        );
      })
      .finally(() => {
        dispatch(setSubmitting(false));
      });
  };
};

export const deleteProfile = (id) => {
  return (dispatch) => {
    dispatch(setLoading(true));
    return request(`Customers/Profiles/${id}`, "delete", null, true)
      .then((response) => {
        dispatch(deleteProfileAction(id));
        openNotificationWithIcon(
          "success",
          "Success!",
          "Family member entry removed successfully"
        );
        return { status: "success" };
      })
      .catch((e) => {
        openNotificationWithIcon(
          "error",
          "Error!",
          e?.response?.data?.message ||
            e?.response?.data?.Message ||
            "Network error has occured"
        );
      })
      .finally(() => dispatch(setLoading(false)));
  };
};

export const forgotUsername = (data) => {
  return (dispatch) => {
    dispatch(setLoading(true));
    return request(
      `Account/ForgotUserName?${queryString.stringify(data)}`,
      "post",
      {},
      false
    )
      .then((response) => {
        return response.data;
      })
      .catch((e) => {
        openNotificationWithIcon(
          "error",
          "Error!",
          e?.response?.data ?? "Network error has occured"
        );
      })
      .finally(() => {
        dispatch(setLoading(false));
      });
  };
};

export const forgotPassword = (data) => {
  return (dispatch) => {
    dispatch(setLoading(true));
    return request(
      `Account/ForgotPassword?${queryString.stringify(data)}`,
      "put",
      {},
      false
    )
      .then((response) => {
        openNotificationWithIcon(
          "info",
          "Reset email sent",
          "Password reset email sent successfully"
        );
        return { status: "success" };
      })
      .catch((e) => {
        openNotificationWithIcon(
          "error",
          "Error!",
          e?.response?.data ?? "Network error has occured"
        );
      })
      .finally(() => {
        dispatch(setLoading(false));
      });
  };
};

export const resetPassword = (data) => {
  return (dispatch) => {
    dispatch(setLoading(true));
    return request(`Account/ResetPassword`, "put", data, false)
      .then((response) => {
        openNotificationWithIcon(
          "success",
          "Success!",
          "Password has been changed successfully"
        );
        return { status: "success" };
      })
      .catch((e) => {
        openNotificationWithIcon(
          "error",
          "Error!",
          e?.response?.data?.[0]?.description ||
            e?.response?.data?.[0]?.code ||
            "Network error has occured"
        );
      })
      .finally(() => {
        dispatch(setLoading(false));
      });
  };
};

export const checkIsRegistrationComplete = (email) => {
  return (dispatch) => {
    dispatch(setLoading(true));
    return request(
      `Account/${email}/IsRegistrationCompleted`,
      "get",
      null,
      false
    )
      .then((response) => {
        return { status: "success" };
      })
      .catch((e) => {
        openNotificationWithIcon(
          "error",
          "Error!",
          e?.response?.data?.message ||
            e?.response?.data?.Message ||
            e?.response?.data?.status ||
            "Network error has occured"
        );
      })
      .finally(() => {
        dispatch(setLoading(false));
      });
  };
};

export const guestInviteReset = (data) => {
  return (dispatch) => {
    dispatch(setLoading(true));
    return request(`Account/CompleteRegistration`, "put", data, false)
      .then((response) => {
        // 1. Login the user and Get token
        request(
          "Account/Login",
          "post",
          {
            username: data.newUserName,
            password: data.newPassword,
          },
          false
        )
          .then((response) => {
            const { token, customer: user } = response.data;
            // 2. Set token in local storage

            setItem("user", user);
            setItem("tokenContainer", { token });
            // 3. Get all profiles
            Promise.all([
              dispatch(getProfiles()),
              dispatch(getAllPaymentMethods(false)),
              dispatch(getAllAddresses(false)),
              dispatch(getRewards()),
              // dispatch(getCustomerWallet()),
              dispatch(getCustomerWallets()),
            ]).then((results) => {
              dispatch(updateUserAction(user));
              // TODO: PUT GET PROFILES CALL IN THE RIGHT SPOT
              // if (results[0]?.length > 1) dispatch(setShowProfileScreen(true));
              dispatch(push("signup?from=complete-registration" ?? "/"));
              dispatch(setLoading(false));
            });
          })
          .catch((e) => {
            throw new Error(e);
          });

        // 3. Set current user

        // 4. Redirect to sign up

        return { status: "success" };
      })
      .catch((e) => {
        openNotificationWithIcon(
          "error",
          "Error!",
          e?.response?.data?.[0]?.description ||
            e?.response?.data?.[0]?.code ||
            "Network error has occured"
        );
        dispatch(setLoading(false));
      });
  };
};

export const externalRegister = (data, token) => {
  return (dispatch) => {
    dispatch(setLoading(true));
    return request(`Account/CompleteRegistration`, "post", data, false, null, {
      headers: {
        accept: "*/*",
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => {
        const { token, customer: user } = response.data;
        // 2. Set token in local storage

        setItem("user", user);
        setItem("tokenContainer", { token: response.data.token });
        dispatch(setRegisteringUser(false));
        dispatch(updateUserAction(user));
        // TODO: PUT GET PROFILES CALL IN THE RIGHT SPOT
        dispatch(getProfiles());
        return user;

        // 3. Set current user
        // 4. Redirect to sign up

        return { status: "success" };
      })
      .catch((e) => {
        openNotificationWithIcon(
          "error",
          "Error!",
          e?.response?.data?.[0]?.description ||
            e?.response?.data?.[0]?.code ||
            "Network error has occured"
        );
      })
      .finally(() => {
        dispatch(setLoading(false));
      });
  };
};

export const getCustomerFiles = (customerId, callback) => {
  return (dispatch) => {
    return request(`Customer/Documents/`, "get", null, true, {
      guestId: customerId,
    })
      .then((result) => {
        callback?.(result?.data);
        return {
          status: "success",
          data: result.data,
        };
      })
      .catch((e) => {
        openNotificationWithIcon(
          "error",
          "Error!",
          e?.response?.message || e?.message || "Something went wrong"
        );
      })
      .finally(() => {
        console.log("problem>");
      });
  };
};

export const getCustomerWallet = () => {
  return (dispatch) => {
    dispatch(
      setCredit({
        isLoading: true,
      })
    );
    return request(`Customer/AvailableCredits`, "get", null, true)
      .then((result) => {
        dispatch(
          setCredit({
            balance: result?.data ?? 0,
            isLoading: false,
          })
        );
        return {
          status: "success",
          data: result.data,
        };
      })
      .catch((e) => {
        openNotificationWithIcon(
          "error",
          "Error!",
          e?.response?.message || e?.message || "Something went wrong"
        );
      })
      .finally(() => {
        dispatch(
          setCredit({
            isLoading: false,
          })
        );
      });
  };
};

export const getCustomerWallets = () => {
  return (dispatch) => {
    dispatch(
      setCustomerWallets({
        isLoading: true,
      })
    );
    dispatch(
      setCredit({
        isLoading: true,
      })
    );
    return request(`Customer/AvailableWallets`, "get", null, true)
      .then((result) => {
        const wallets = result?.data ?? [];

        dispatch(
          setCredit({
            balance: wallets
              ?.filter((wallet) => wallet.isGeneral === true)
              ?.reduce((acc, wallet) => acc + wallet.balance, 0),
            isLoading: false,
          })
        );
        dispatch(
          setCustomerWallets({
            data: result?.data ?? 0,
            isLoading: false,
          })
        );
        return {
          status: "success",
          data: result.data,
        };
      })
      .catch((e) => {
        openNotificationWithIcon(
          "error",
          "Error!",
          e?.response?.message || e?.message || "Something went wrong"
        );
      })
      .finally(() => {
        dispatch(
          setCustomerWallets({
            isLoading: false,
          })
        );
        dispatch(
          setCredit({
            isLoading: false,
          })
        );
      });
  };
};

export const getCustomerReferrals = () => {
  return (dispatch) => {
    return request(`Customer/referrals`, "get", null, true)
      .then((result) => {
        return {
          status: "success",
          data: result.data,
        };
      })
      .catch((e) => {
        openNotificationWithIcon(
          "error",
          "Error!",
          e?.response?.message || e?.message || "Something went wrong"
        );
      })
      .finally(() => {
        console.log("problem>");
      });
  };
};
export const getReferralPoliciesTC = () => {
  return (dispatch) => {
    return request(`ReferralPolicies/Active`, "get", null, true)
      .then((result) => {
        // let tc = undefined;
        // if (result?.data?.length > 0) {
        //   let activePolicy = result.data.find((policy) => policy.isActive);
        //   tc = activePolicy?.termsAndConditions;
        // }
        return {
          status: "success",
          data: result?.data?.termsAndConditions || "",
        };
      })
      .catch((e) => {
        openNotificationWithIcon(
          "error",
          "Error!",
          e?.response?.message || e?.message || "Something went wrong"
        );
      })
      .finally(() => {
        console.log("problem>");
      });
  };
};
