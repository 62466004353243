import React, { useMemo, useState, useCallback } from "react";
import { Typography, Space, Button, Tooltip, Modal } from "antd";
import { useDispatch, useSelector } from "react-redux";
import {
  applyReward,
  changeWalletPreferance,
  deleteRewards,
} from "services/booking.service";
import { setLoading } from "redux/actions/loading.actions";
import OrderSummaryItem from "./OrderSummaryItem";
import { getRewardId, getRewardsText } from "utils/common";
import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  CrownOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import RewardPopup from "components/RewardPopup/RewardPopup";
import { openNotificationWithIcon } from "utils/Notification";
import { BenefitType } from "utils/enums";
import AddonItem from "./AddonItem";
import { min } from "lodash";

const { Text, Title, Link } = Typography;
const AdditionalBoostsDummy = [
  { name: "Glutathione", price: 50 },
  {
    name: "Tri-immune Boost",
    price: 50,
  },
];

const DUMMY_HAS_MEMBERSHIP = false;
const VOUCHER_AVAILED = false;

function OrderSummary({
  order,
  product,
  onProductChange,
  addOns,
  setServiceModal,
  removeAddon,
  getData,
  appointments,
  isMultipleGuestBooking,
}) {
  const orderId = order?.orderId;
  // const [product, setProduct] = useState(order?.lineItems?.[0]?.product);
  const dispatch = useDispatch();
  const [showMainServiceRewardsPopup, setshowMainServiceRewardsPopup] =
    useState(false);

  const {
    rewards,
    credit: { balance, isLoading: isCreditLoading },
  } = useSelector((state) => ({
    rewards: state.rewards,
    credit: state.paymentMethod.credit,
  }));

  const hasAvailableReward = useMemo(() => {
    if (order?.lineItems?.[0] && !order?.lineItems?.[0]?.rewardId) {
      return getRewardId(rewards?.rewards ?? [], order?.lineItems?.[0], []);
    }
    return false;
  }, [order, rewards]);
  const isOrderCompleted =
    order?.orderStatus === 3 || order.orderStatus === "Completed";
  const isOrderCancelled =
    order?.orderStatus === 2 || order.orderStatus === "Cancel";

  const [total, giftcard, taxAmount] = useMemo(() => {
    let total = 0;
    let taxAmount = 0;
    for (let i = 0; i < order?.lineItems?.length; i++) {
      total = total + order?.lineItems[i].subTotal;
      taxAmount += order?.lineItems[i]?.taxAmount ?? 0;
      for (let j = 0; j < order?.lineItems[i].addOns?.length; j++) {
        total = total + order?.lineItems[i].addOns[j].subTotal;
        taxAmount += order?.lineItems[i]?.addOns[j]?.taxAmount ?? 0;
      }
    }

    for (let i = 0; i < order?.appointments?.length; i++) {
      const appointment = order?.appointments[i];
      for (let j = 0; j < appointment?.lineItems?.length; j++) {
        total = total + appointment?.lineItems[j].subTotal;
        taxAmount += appointment?.lineItems[j]?.taxAmount ?? 0;
        for (let k = 0; k < appointment?.lineItems[j].addOns?.length; k++) {
          total = total + appointment?.lineItems[j].addOns[k].subTotal;
          taxAmount += appointment?.lineItems[j]?.addOns[k]?.taxAmount ?? 0;
        }
      }
    }
    if (order?.voucherCode && order?.voucherDiscount) {
      total = total - order?.voucherDiscount;
    }

    if (order?.goodFaithPolicyFee) {
      total = total + order.goodFaithPolicyFee;
    }

    let giftcard = 0;
    if (order?.useWallet) {
      // const amount = min([total ?? 0, balance ?? 0])
      // giftcard = isOrderCompleted
      //   ? order?.giftCardAmount
      //   : amount < 0 ? 0 : amount ;
      giftcard = order?.giftCardAmount;
    }

    total = total - giftcard;

    if (!(isOrderCompleted || isOrderCancelled) && order?.amountPaidUntilNow) {
      total = total - order?.amountPaidUntilNow;
    }

    // total is just the cancellation amount if cancellated
    if (isOrderCancelled) {
      total = order?.cancellationFee;
      giftcard = 0;
    }

    // total += Number(order?.taxAmount || 0);
    return [total, giftcard, taxAmount];
  }, [order, balance, isOrderCompleted, isOrderCancelled]);

  const deleteReward = async (lineItemId) => {
    await dispatch(deleteRewards(order, lineItemId));
  };

  const toggleWalletPreferance = () => {
    dispatch(changeWalletPreferance(order, order?.useWallet ? false : true));
  };

  const deleteConfirmation = (id) => ({
    title: "Remove Reward",
    content: <>Are you sure that you want to remove the reward?</>,
    centered: true,
    maskClosable: true,
    onOk: (close) => {
      deleteReward(id);
      close();
    },
  });

  const renderLineItems = () => {
    return order?.lineItems?.map((item) => {
      return (
        <div>
          {/* Main Item Start */}

          <div
            className="flex alignCenter justifySpaceBetween"
            style={{ marginBottom: 4 }}
          >
            <Space size={"middle"}>
              <Title
                level={5}
                className="tradeGothic"
                style={{ marginBottom: 0, fontSize: 17 }}
              >
                {item.productName}{" "}
                {/* {item.durationHours !== undefined &&
                  item.durationHours >= 1 &&
                  ` (${Number(item.durationHours).toFixed(0)} hr)`} */}
              </Title>
              {hasAvailableReward && (
                <Button
                  onClick={() => setshowMainServiceRewardsPopup(true)}
                  size="small"
                  type="primary"
                  shape="circle"
                  hidden={isOrderCancelled || isOrderCompleted}
                  disabled={isOrderCancelled || isOrderCompleted}
                  style={{
                    backgroundColor: "#2abb2a",
                    border: "#295b29",
                    cursor: "pointer",
                  }}
                  className=""
                  icon={<CrownOutlined />}
                />
              )}
              {/* <Link onClick={() => setServiceModal(item.lineItemId)} underline disabled={orderId === undefined}>
                Change
              </Link> */}
            </Space>

            <Title
              level={5}
              className="tradeGothic"
              style={{ marginBottom: 0, fontSize: 17 }}
            >
              ${Number(item.sellingPrice ?? 0)?.toFixed?.(2)}{" "}
              {/* {item?.taxAmount > 0 && (
                <>+ ${Number(item?.taxAmount ?? 0)?.toFixed?.(2)}</>
              )} */}
              {/* {item.benefitType == BenefitType["Bonus Service"]
                ? `${Number(0).toFixed(2)}`
                : `${Number(item.sellingPrice ?? 0).toFixed(2)}`} */}
            </Title>
          </div>
          {item.rewardId && (
            <div
              className="flex alignCenter justifySpaceBetween"
              style={{
                marginBottom: 14,
              }}
            >
              <Space size={"middle"}>
                <Title
                  level={5}
                  className="tradeGothic"
                  style={{
                    marginBottom: 0,
                    fontSize: 17,
                    color: "#27ca2d",
                  }}
                >
                  Rewards : {getRewardsText(item)}
                </Title>
                {/* <Link onClick={() => setServiceModal(item.lineItemId)} underline disabled={orderId === undefined}>
                Change 
              </Link> */}
              </Space>
              <Space>
                <Button
                  size="small"
                  shape="circle"
                  danger
                  hidden={isOrderCancelled || isOrderCompleted}
                  disabled={isOrderCancelled || isOrderCompleted}
                  icon={<DeleteOutlined style={{ color: "#ea5961" }} />}
                  onClick={() =>
                    Modal.warning(deleteConfirmation(item?.lineItemId))
                  }
                />
                {!!item?.rewardId && (
                  <span
                    style={{
                      color: "#27ca2d",
                      fontSize: 18,
                      fontFamily: "tradeGothic",
                      fontWeight: "bold",
                    }}
                  >
                    -${Number(item.discountValue ?? 0)?.toFixed?.(2)}
                  </span>
                )}
              </Space>
            </div>
          )}

          {/* Main Item End */}
          {/* Additional Boosts Start */}
          <div style={{ marginBottom: 12 }}>
            <Title
              level={5}
              className="tradeGothic"
              style={{ marginBottom: 4, fontSize: 17 }}
            >
              Additional Add-ons
            </Title>
            {item?.addOns?.length ? (
              item?.addOns.map((addon) => {
                return (
                  <AddonItem
                    addon={addon}
                    removeAddon={removeAddon}
                    lineItemId={item.lineItemId}
                    deleteReward={deleteReward}
                    order={order}
                    disabled={isOrderCancelled || isOrderCompleted}
                  />
                );
              })
            ) : (
              <div>
                <Text className="tradeGothic" style={{ fontSize: 17 }}>
                  No additional add-ons selected
                </Text>
              </div>
            )}
          </div>
          {/* Additional Add-ons End */}
        </div>
      );
    });
  };

  const renderGuestsLineItems = () => {
    return order?.appointments?.map?.((appointment, index) => {
      return (
        <div>
          <Space size={"middle"}>
            <Title
              level={5}
              className="tradeGothic"
              style={{
                marginBottom: 4,
                fontSize: 17,
                color: "rgb(72, 107, 175)",
              }}
            >
              Guest: {appointment.customerFullName}{" "}
              {/* {item.durationHours !== undefined &&
                  item.durationHours >= 1 &&
                  ` (${Number(item.durationHours).toFixed(0)} hr)`} */}
            </Title>
            {/* <Link onClick={() => setServiceModal(item.lineItemId)} underline disabled={orderId === undefined}>
                Change
              </Link> */}
          </Space>
          {appointment?.lineItems?.map?.((item) => {
            return (
              <OrderSummaryItem
                order={order}
                orderId={appointment.orderId}
                item={item}
              />
            );
          })}
        </div>
      );
    });
  };

  const getAvailableRewards = () => {};

  return (
    <div>
      {renderLineItems()}
      {renderGuestsLineItems()}
      {/* Gift Card Amount Start */}
      {order?.voucherCode && (
        <div
          className="flex alignCenter justifySpaceBetween"
          style={{ paddingLeft: 17 }}
        >
          <Text
            className="tradeGothic"
            style={{ fontSize: 17, marginBottom: 0 }}
          >
            {order?.voucherDiscount > 0 ? (
              <>
                You saved
                <span style={{ fontWeight: 700 }}>
                  {" "}
                  ${order?.voucherDiscount}{" "}
                </span>
                on this order!
              </>
            ) : (
              <>
                A{" "}
                <span style={{ fontWeight: 700 }}>
                  {" "}
                  ${Math.abs(Number(order?.voucherDiscount))}{" "}
                </span>{" "}
                fee was charged on this order
              </>
            )}
          </Text>
          <Title
            level={5}
            className="tradeGothic"
            style={{
              marginBottom: 0,
              fontSize: 17,
              color: "#486BAF",
              marginTop: 0,
            }}
          >
            {Number(order?.voucherDiscount > 0) ? "-" : ""}$
            {Math.abs(Number(order?.voucherDiscount)).toFixed(2)}
          </Title>
        </div>
      )}
      {/* Gift Card Amount End */}
      {/* Membership Benefits Start */}
      {DUMMY_HAS_MEMBERSHIP && (
        <div
          className="flex alignCenter justifySpaceBetween"
          style={{
            marginTop: 16,
            borderTop: "1px solid #E4EBE7",
            paddingTop: 16,
            borderBottom: "1px solid #E4EBE7",
            paddingBottom: 16,
          }}
        >
          <Title
            level={5}
            className="tradeGothic"
            style={{ marginBottom: 0, fontSize: 17 }}
          >
            Basic Membership Discout
          </Title>
          <Title
            level={5}
            className="tradeGothic"
            style={{
              marginBottom: 0,
              fontSize: 17,
              color: "#486BAF",
              marginTop: 0,
            }}
          >
            -${Number(39).toFixed(2)}
          </Title>
        </div>
      )}
      {/* Membership Benefits End */}
      {/* Total Start */}
      <div
        className="flex  justifyEnd"
        style={{
          marginTop: DUMMY_HAS_MEMBERSHIP ? 0 : 16,
          borderTop: DUMMY_HAS_MEMBERSHIP ? "none" : "1px solid #E4EBE7",
          paddingTop: 16,
          flexDirection: "column",
          alignItems: "flex-end",
          // borderBottom: "1px solid #E4EBE7",
          // paddingBottom: 16,
        }}
      >
        {giftcard > 0 && (
          <div className="orderTotal">
            <Title
              level={5}
              className="tradeGothic"
              style={{
                marginBottom: 0,
                fontSize: 17,
                marginTop: 0,
              }}
            >
              Gift Card:
            </Title>
            <Title
              level={5}
              className="tradeGothic"
              style={{
                marginBottom: 0,
                fontSize: 17,
                marginTop: 0,
                color: "#52c41a",
              }}
            >
              -${Number(giftcard || 0).toFixed(2)}
              &nbsp;
              <Tooltip
                title={
                  isOrderCancelled || isOrderCompleted
                    ? ""
                    : "Toggle Gift Card Preferance."
                }
              >
                {order?.useWallet ? (
                  <CheckCircleOutlined
                    onClick={() => {
                      if (isOrderCancelled || isOrderCompleted) {
                        return;
                      }
                      toggleWalletPreferance();
                    }}
                    style={{
                      cursor:
                        isOrderCancelled || isOrderCompleted
                          ? "default"
                          : "pointer",
                      color: "#52c41a",
                    }}
                  />
                ) : (
                  <CloseCircleOutlined
                    onClick={() => {
                      if (isOrderCancelled || isOrderCompleted) {
                        return;
                      }
                      toggleWalletPreferance();
                    }}
                    style={{
                      cursor:
                        isOrderCancelled || isOrderCompleted
                          ? "default"
                          : "pointer",
                      color: "#ff4d4f",
                    }}
                  />
                )}
              </Tooltip>
            </Title>
          </div>
        )}
        <div className="orderTotal">
          <Title
            level={5}
            className="tradeGothic"
            style={{
              marginBottom: 0,
              fontSize: 17,
              marginTop: 0,
            }}
          >
            Tax:
          </Title>
          <Title
            level={5}
            className="tradeGothic"
            style={{
              marginBottom: 0,
              fontSize: 17,
              marginTop: 0,
            }}
          >
            ${Number(taxAmount || 0).toFixed(2)}
          </Title>
        </div>
        {isOrderCancelled && order?.cancellationFee > 0 && (
          <div className="orderTotal">
            <Title
              level={5}
              className="tradeGothic"
              style={{
                marginBottom: 0,
                fontSize: 17,
                marginTop: 0,
              }}
            >
              Cancellation Fee:
            </Title>
            <Title
              level={5}
              className="tradeGothic"
              style={{
                marginBottom: 0,
                fontSize: 17,
                marginTop: 0,
              }}
            >
              ${Number(order?.cancellationFee || 0).toFixed(2)}
            </Title>
          </div>
        )}
        {order?.goodFaithPolicyFee > 0 && (
          <div className="orderTotal">
            <Title
              level={5}
              className="tradeGothic"
              style={{
                marginBottom: 0,
                fontSize: 17,
                marginTop: 0,
              }}
            >
              Good Faith Policy Fee:
            </Title>
            <Title
              level={5}
              className="tradeGothic"
              style={{
                marginBottom: 0,
                fontSize: 17,
                marginTop: 0,
              }}
            >
              ${Number(order?.goodFaithPolicyFee || 0).toFixed(2)}
            </Title>
          </div>
        )}

        {!(isOrderCompleted || isOrderCancelled) &&
          order?.amountPaidUntilNow > 0 && (
            <div className="orderTotal">
              <Title
                level={5}
                className="tradeGothic"
                style={{
                  marginBottom: 0,
                  fontSize: 17,
                  marginTop: 0,
                }}
              >
                Amount Paid Until Now:
              </Title>
              <Title
                level={5}
                className="tradeGothic"
                style={{
                  marginBottom: 0,
                  fontSize: 17,
                  marginTop: 0,
                  color: "#52c41a",
                }}
              >
                -${Number(order?.amountPaidUntilNow || 0).toFixed(2)}
              </Title>
            </div>
          )}

        <div className="orderTotal">
          <Title
            level={5}
            className="tradeGothic"
            style={{
              marginBottom: 0,
              fontSize: 17,
              marginTop: 0,
            }}
          >
            Total:
          </Title>
          <Title
            level={5}
            className="tradeGothic"
            style={{
              marginBottom: 0,
              fontSize: 17,
              marginTop: 0,
            }}
          >
            ${Number(Math.max(0, total)).toFixed(2)}
          </Title>
        </div>
      </div>
      {/* Total End */}
      {/* Main service reward popup */}
      <RewardPopup
        values={order?.lineItems?.[0]}
        availedRewards={[]}
        visible={showMainServiceRewardsPopup}
        onCancel={() => setshowMainServiceRewardsPopup(false)}
        onRedeem={(rewardId) => {
          dispatch(
            applyReward(
              order,
              order?.lineItems?.[0]?.lineItemId,
              rewardId,
              () => {
                openNotificationWithIcon(
                  "success",
                  "Success",
                  "Reward applied successfully"
                );
                setshowMainServiceRewardsPopup(false);
              }
            )
          );
        }}
        facilityId={order?.facilityId}
      />
      {/* Main service reward popup end */}
    </div>
  );
}

OrderSummary.propTypes = {};

export default OrderSummary;
