import React, { useRef, useLayoutEffect, useEffect, useState } from "react";
import PropTypes from "prop-types";

import { Typography, Empty } from "antd";
import { RightOutlined, LeftOutlined } from "@ant-design/icons";
import "./HorizontalSlide.scss";

const { Title } = Typography;
const Padding = 20;

function HorizontalSlide({ children, className, title, emptyText }) {
  const containerRef = useRef(null);

  const [leftDisabled, setLeftDisabled] = useState(false);
  const [rightDisabled, setRightisabled] = useState(false);

  function reconcileScrollWidth(container) {
    if (container.scrollLeft === 0) {
      setLeftDisabled(true);
      // setRightisabled(true)
    } else {
      setLeftDisabled(false);
    }
    if (
      container.scrollLeft >=
      container.scrollWidth - container.clientWidth - 80
    ) {
      setRightisabled(true);
    } else {
      setRightisabled(false);
    }
  }

  useEffect(() => {
    const container = containerRef.current;
    if (container) {
      reconcileScrollWidth(container);
    }
  }, []);

  const renderControls = () => {
    return (
      <>
        <button
          type="button"
          hidden={leftDisabled}
          disabled={leftDisabled}
          style={{
            backgroundColor: leftDisabled ? "#486baf80" : "#486baf",
          }}
          className="controller arrowLeft"
          onClick={(e) => {
            e.stopPropagation();
            containerRef.current.scrollLeft -= 200;
          }}
        >
          <LeftOutlined />
        </button>
        <button
          type="button"
          hidden={rightDisabled}
          disabled={rightDisabled}
          style={{
            backgroundColor: rightDisabled ? "#486baf80" : "#486baf",
          }}
          className="controller arrowRight"
          onClick={(e) => {
            e.stopPropagation();
            containerRef.current.scrollLeft += 200;
          }}
        >
          <RightOutlined />
        </button>
      </>
    );
  };
  return (
    <div className={`${className} horizontalSlideContainer`}>
      {title && (
        <Title level={4} className="tradeGothic">
          {title}
        </Title>
      )}
      <div>
        <div
          className="horizontalSlide"
          ref={containerRef}
          onScroll={() => {
            const container = containerRef.current;
            reconcileScrollWidth(container);
          }}
        >
          {children?.length > 0 ? (
            <>{children}</>
          ) : (
            <Empty
              style={{ width: "100%" }}
              description={emptyText}
            />
          )}
        </div>
        {renderControls()}
      </div>
    </div>
  );
}

HorizontalSlide.propTypes = {
  title: PropTypes.string,
  children: PropTypes.any,
  className: PropTypes.string,
};

export default HorizontalSlide;
