import Modal from "components/Modal/Modal";
import React from "react";

export default function PromoGiftCardModal() {
  return (
    <Modal visible={false} title="Promo Gift Card">
      <div>
        <h4>Gift Card Name</h4>
        <div>Description</div>
        <div>Price: $100</div>
      </div>
    </Modal>
  );
}
