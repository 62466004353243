export const OperationType = {
  All: 0,
  Mobile: 1,
  Clinic: 2,
  Telehealth: 3,
};

export const ProductType = {
  Service: 1,
  Boost: 2,
  Telehealth: 3,
};

export const FormType = {
  chart: 1,
  consent: 3,
  historyOrPhysical: 2,
};

export const PaymentMethodType = {
  wallet: 3,
  cash: 2,
  card: 1,
};

export const ExcludeType = {
  "All Included": 1,
  "Only Included": 2,
  "All Included Except": 3,
};

export const RewardType = {
  Program: 1,
  Membership: 2,
  "Voucher Code": 3,
  Referral: 4,
  "Gift Card": 5,
};

export const BenefitType = {
  "Discount Amount": 1,
  "Discount Percentage": 2,
  "Bonus Service": 3,
  Referrals: 4,
};

export const MeetingVendor = {
  Zoom: 1,
  Google: 2,
};

export const PROGRAM_TYPE = {
  Program: 1,
  Membership: 2,
  "Voucher Code": 3,
  Referral: 5,
  "Promotional Gift Card": 4,
  // "Gift Card": 5,
};
